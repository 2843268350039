<template>
  <SetupForm
    v-bind="$attrs"
    :title="$t('settings.automation.account.setup.title')"
    :next-text="$t('settings.automation.account.setup.nextText')"
    v-on="$listeners"
  >
    <VxFormGroup
      v-if="hasAccountSlot"
      :title="$t('settings.automation.account.setup.groupTitle')"
    >
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <slot name="account" v-bind="accountProps" />
        </v-col>
      </v-row>
    </VxFormGroup>
    <slot></slot>
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import { VxFormGroup } from "@/core-ui";

export default {
  name: "AccountSetup",
  components: { SetupForm, VxFormGroup },
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasAccountSlot() {
      return "account" in this.$scopedSlots;
    },
    accountProps() {
      return {
        attrs: {
          items: this.accounts,
          rules: "required",
        },
        on: {
          add: this.addAccount,
          change: this.changeAccount,
        },
      };
    },
  },
  methods: {
    addAccount(context) {
      this.$emit("add:account", context);
    },
    changeAccount(id) {
      const selected = this.accounts.find((x) => x.value === id);
      this.$emit("change:account", selected);
    },
  },
};
</script>
