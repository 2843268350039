<template>
  <VxSelect
    class="account-select"
    v-bind="$attrs"
    :no-data-text="$t('settings.automation.account.select.noDataText')"
    :label="$t('settings.automation.account.select.label')"
    :placeholder="$t('settings.automation.account.select.placeholder')"
    :menu-props="{ closeOnContentClick: true }"
    :loading="loading"
    v-on="$listeners"
  >
    <template #append-item>
      <v-divider />
      <v-list-item @click="addAccount">
        <v-list-item-action>
          <v-icon>$plus</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("settings.automation.account.select.addAccountText") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #item="{ item }">
      <AccountSelectItem :item="item" />
    </template>
    <template #selection="{ item }">
      <AccountSelectItem :item="item" />
    </template>
  </VxSelect>
</template>

<script>
import { VxSelect } from "@/core-ui";
import AccountSelectItem from "./AccountSelectItem.vue";

export default {
  name: "AccountSelect",
  components: { VxSelect, AccountSelectItem },
  inheritAttrs: false,
  props: {
    loadable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    addAccount() {
      if (this.loadable) {
        this.loading = true;
      }

      const resolve = () => {
        if (this.loadable) {
          this.loading = false;
        }
      };

      this.$emit("add", { resolve });
    },
  },
};
</script>
