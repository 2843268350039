<template>
  <div class="account-select__item">
    <span class="mr-3">{{ item.text }}</span>
    <span v-if="storeInfo" class="text-caption text--secondary">
      ({{ storeInfo }})
    </span>
  </div>
</template>

<script>
export default {
  name: "AccountSelectItem",
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    storeInfo() {
      if (!this.item?.storeNumbers?.length) {
        return;
      }

      return this.$tc(
        "settings.automation.account.select.usedByStoreText",
        this.item.storeNumbers.length - 1,
        {
          storeNumber: this.item.storeNumbers[0],
          othersCount: this.item.storeNumbers.length - 1,
        }
      );
    },
  },
};
</script>
