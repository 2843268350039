import Vue from "vue";
import gql from "graphql-tag";
import { useStoreView } from "./useStoreView";

/**
 * @typedef {Object} AccountViewOptions
 * @property {String} prop - define prop which contains accounts on given query
 * @property {String} query - define GQL query to execute to retrive automation accounts
 */

/**
 * Define current view as account view to get access to automation `accounts` computed binded with store numbers
 * @param {AccountViewOptions} options - the options to define account view usage
 * @returns extendable Vue instance
 */
export function useAccountView(options) {
  const { prop, query } = options;
  return Vue.extend({
    name: "AccountView",
    mixins: [useStoreView()],
    data: () => ({
      accountList: [],
    }),
    apollo: {
      accountList: {
        query: gql`
          ${query}
        `,
        variables() {
          return { storeId: this.storeId };
        },
        update(data) {
          return data[prop];
        },
        skip() {
          return !this.storeId;
        },
      },
    },
    computed: {
      accounts() {
        return this.accountList.map((account) => ({
          ...account,
          storeNumbers: account.storeIds?.map((id) =>
            this.storeNumbers.get(id)
          ),
        }));
      },
    },
  });
}
